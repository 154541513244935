try {
    window.$ = window.jQuery = require('jquery');

    require('./jquery.cycle');
    require('fancybox')($);
} catch (e) {}

window.Cookies = require('js-cookie');

const $cookies = $('#cookies');

if (!Cookies.get('cookies-read')) {
    $cookies.removeClass('hidden');
}

$('#close-cookies-info').click(e => {
    $cookies.addClass('hidden');

    Cookies.set('cookies-read', true);

    e.preventDefault();
});

$('a.modal-image').fancybox({
    titlePosition: 'over',
    afterLoad : function(instance, current) {
        const pixelRatio = window.devicePixelRatio || 1;

        console.log(pixelRatio);
        if ( pixelRatio > 1.5 ) {
            current.width  = current.width  / pixelRatio;
            current.height = current.height / pixelRatio;
        }
    }
});

/* Slider */

const $slider = $('#homepage-slider');

if($slider.length) {
    $slider.cycle({
        pager: '#homepage-slider-nav',
        pagerAnchorBuilder: function() {
            return '<a href="#"></a>';
        }
    });
}


/* Map */
(function() {
    const $map = $('#map');

    if( $map.length ) {
        const
            $back = $('#back-to-map'),
            $posList = $('#pos-list'),
            $posSort = $('#pos-sort'),
            $items = $posList.find('> li'),
            posPosition = $posList.offset().top;

        $posSort.on('click', 'a', function(e) {
            const
                $this = $(this),
                orderName = $this.data('order');

            $posSort.find('.active').removeClass('active');
            $this.addClass('active');

            $items.sort(function(a, b) {
                const
                    A = $(a).data(orderName).toLowerCase(),
                    B = $(b).data(orderName).toLowerCase();

                if(A < B) {
                    return -1;
                }
                else if (A > B) {
                    return  1;
                }
                else {
                    return 0;
                }
            });

            $posList.empty().append( $items );

            e.preventDefault();
        });

        $map
            .children('a.marker')
            .each(function() {
                const $this = $(this);

                $this.css({
                    top: $this.data('y'),
                    left: $this.data('x')
                });
            })
            .end()
            .on('click', 'a', function(e) {
                const target = $(this).attr('href');

                scrollTo(target);
                $posList.find('.active').removeClass('active');
                $(target).addClass('active');

                e.preventDefault();
            });

        $back.on('click', function(e) {
            const target = $(this).attr('href');

            scrollTo(target);
            $posList.find('.active').removeClass('active');

            e.preventDefault();
        });

        $map.click(function(e) {
            console.log({
                x: e.offsetX,
                y: e.offsetY
            });
        });

        $(window).scroll(function() {
            const top = $(document).scrollTop();

            if(top > (posPosition - 300) ) {
                $back.fadeIn().animate({
                    top: top
                }, {
                    duration: 500,
                    queue: false
                });
            } else {
                $back.fadeOut();
            }
        });
    }
})();

function scrollTo(target) {
    $('html, body').animate({
        scrollTop: $(target).offset().top - 45
    }, 250);
}

